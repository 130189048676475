import React from "react";

type Fill = "dark" | "light";

const LogoCoda = (props: { fill: Fill }) => {
  const fill = props.fill === "dark" ? "#2D3748" : "white";

  return (
    <svg
      width="65"
      height="24"
      viewBox="0 0 65 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M7.69466 19.7931C6.12433 19.7931 4.72847 18.764 4.34461 17.2889C3.92585 15.7796 4.58888 14.2359 5.94984 13.4469C7.3108 12.6923 9.02073 12.8981 10.1374 13.9958L13.1036 11.0457C10.1025 8.09561 5.25191 8.09561 2.25082 11.0457C-0.750273 13.9958 -0.750273 18.7983 2.25082 21.7484C5.25191 24.6985 10.1025 24.6985 13.1036 21.7484L10.1374 18.7983C9.47438 19.45 8.60197 19.7931 7.69466 19.7931Z"
          fill={fill}
        />
        <path
          d="M21.701 8.88459C18.5952 8.88459 15.7686 10.737 14.5821 13.5499C13.3956 16.3627 14.0586 19.6216 16.2571 21.7827C18.4556 23.9438 21.7707 24.5956 24.6323 23.3949C27.4938 22.2286 29.3782 19.4843 29.3782 16.4313C29.3433 12.2806 25.9234 8.88459 21.701 8.88459ZM21.701 19.8274C20.2702 19.8274 19.0139 19.0041 18.4905 17.7349C17.967 16.4656 18.2462 14.9906 19.2233 14.0301C20.2353 13.0696 21.701 12.7609 22.9921 13.3097C24.3182 13.8243 25.1557 15.0592 25.1557 16.4313C25.1557 18.318 23.5854 19.8274 21.701 19.8274Z"
          fill={fill}
        />
        <path
          d="M42.6039 0V9.70787C39.3586 8.09561 35.4501 8.9532 33.147 11.6975C30.8787 14.476 30.8787 18.4209 33.147 21.1995C35.4501 23.9438 39.3586 24.8014 42.6039 23.1891V23.9781H46.8264V0H42.6039ZM39.1492 19.8617C37.2299 19.8617 35.6944 18.318 35.6944 16.4313C35.6944 14.579 37.2299 13.0353 39.1492 13.0353C41.0336 13.0353 42.6039 14.579 42.6039 16.4313C42.6039 18.318 41.0336 19.8274 39.1492 19.8617Z"
          fill={fill}
        />
        <path
          d="M60.3929 8.88459V9.70787C57.1476 8.09561 53.2392 8.9532 50.936 11.6975C48.6677 14.476 48.6677 18.4209 50.936 21.1995C53.2392 23.9438 57.1476 24.8014 60.3929 23.1891V23.9781H64.6154V8.88459H60.3929ZM56.9382 19.8617C55.0189 19.8617 53.4834 18.318 53.4834 16.4313C53.4834 14.579 55.0189 13.0353 56.9382 13.0353C58.8226 13.0353 60.3929 14.579 60.3929 16.4313C60.3929 18.318 58.8226 19.8274 56.9382 19.8617Z"
          fill={fill}
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="64.6154"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.101961 0 0 0 0 0.12549 0 0 0 0 0.172549 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default LogoCoda;
